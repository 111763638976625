<template>
  <v-footer
    dark
    padless
  >
    <v-card
      class="flex"
      flat
      tile
      color="white"
      light
      height="105px"
    >
      <v-card-title>
        <v-btn
          v-for="(link, index) in links"
          :key="index"
          color="#008DBC"
          text
          rounded
          class="my-2"
          @click="toRoute(link.routeName)"
        >
          {{ link.name }}
        </v-btn>

        <v-spacer></v-spacer>

        
      </v-card-title>

    </v-card>
  </v-footer>
</template>

<script>
import router from '../router'
import { mapState } from 'vuex'

export default {
  data: () => ({

  }),
  computed: {
    ...mapState(['user']),
  },
  methods: {
    toRoute(routeName) {
      if(this.$route.name !== routeName) {
        router.push({ name: routeName })
        const tabIndex = this.links.findIndex(tab => tab.routeName === routeName)
        this.$emit('updateSelectedTab', tabIndex)
      }
    }
  },
  props: {
    links: Array,
  }
}
</script>

<style scoped>
.text {
  font-size: 10pt;
  font-family: "NunitoSans-Bold";
  color: #008DBC;
}
</style>