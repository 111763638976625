
import router from '@/router'
import { Auth, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, signOut } from '@/firebase/auth'
import { DB, setDoc, doc, getDoc } from '@/firebase/db'

import { refreshPaycorOAuth, refreshPaycorOAuthSandbox, refreshAdoOAuth, refreshSessionIdAdaptiveWorkSandbox } from '../../../firebase/functions'

export default {
  state: {
    firstName: '',
    lastName: '',
    email: '',
    emailVerified: false,
    isAnonymous: false,
    uid: '',
    isAdmin: false,
    isUser: false,
    accessTokenAdo: '',
    refreshTokenAdo: '',
    accessTokenPaycor: '',
    refreshTokenPaycor: '',
    accessTokenPaycorSandbox: '',
    refreshTokenPaycorSandbox: '',
    sessionIdAdaptiveWorkSandbox: '',
  },
  getters: {
    user: (state) => state 
  },
  mutations: {
    updateUserState(state, userProfile) {
      state.firstName = userProfile.firstName
      state.lastName = userProfile.lastName
      state.email = userProfile.email
      state.isAnonymous = userProfile.isAnonymous
      state.uid = userProfile.uid
      state.isAdmin = userProfile.isAdmin
      state.isUser = userProfile.isUser
      state.accessTokenAdo = userProfile.accessTokenAdo
      state.refreshTokenAdo = userProfile.refreshTokenAdo
      state.accessTokenPaycor = userProfile.accessTokenPaycor
      state.refreshTokenPaycor = userProfile.refreshTokenPaycor
      state.accessTokenPaycorSandbox = userProfile.accessTokenPaycorSandbox
      state.refreshTokenPaycorSandbox = userProfile.refreshTokenPaycorSandbox
      state.sessionIdAdaptiveWorkSandbox = userProfile.sessionIdAdaptiveWorkSandbox

      if(router.currentRoute.name === 'Home') {
        router.push({ name: 'RegistrationLanding' })
      }
    },
    updatePaycorOauthTokens(state, userProfile) {
      state.accessTokenPaycor = userProfile.accessTokenPaycor
      state.refreshTokenPaycor = userProfile.refreshTokenPaycor
    },
    updatePaycorOauthTokensSandbox(state, userProfile) {
      state.accessTokenSandbox = userProfile.accessTokenSandbox
      state.refreshTokenSandbox = userProfile.refreshTokenSandbox
    },
    updateAdaptiveWorkSessionIdSandbox(state, userProfile) {
      state.sessionIdAdaptiveWorkSandbox = userProfile.sessionIdAdaptiveWorkSandbox
    },
    updateAdoOauthTokens(state, userProfile) {
      state.accessTokenAdo = userProfile.accessTokenAdo
      state.refreshTokenAdo = userProfile.refreshTokenAdo
    },
    resetUserState(state) {
      state.firstName = ''
      state.lastName = ''
      state.email = ''
      state.emailVerified = false
      state.isAnonymous = false
      state.uid = ''
      state.isAdmin = false
      state.isUser = false
      state.accessTokenAdo = ''
      state.refreshTokenAdo = ''
      state.accessTokenPaycor = ''
      state.refreshTokenPaycor = ''
      state.accessTokenPaycorSandbox = ''
      state.refreshTokenPaycorSandbox = ''
      state.sessionIdAdaptiveWorkSandbox = ''
    },
  },
  actions: {
    async registerAccount({ commit }, payload) {
      try {
        // User Credential Interface: https://firebase.google.com/docs/reference/js/auth.usercredential
        const userCredential = await createUserWithEmailAndPassword(Auth, payload.email, payload.password)
        const user = userCredential.user
        const userProfile = {
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email,
          uid: user.uid,
          emailVerified: user.emailVerified,
          isAnonymous: user.isAnonymous,
        }

        try {
          const userRef = await doc(DB, 'user', user.uid)
          await setDoc(userRef, userProfile)
          commit('updateUserState', userProfile)
        } catch (e) {
          alert('Vuex User Store (action: registerAccount) (Firebase Error):', e.message)
        }
      } catch (e) {
        alert('Vuex User Store (action: registerAccount) (Account Registration Error):', e.message)
      }
    },
    
    async loginAccount({ commit }, payload) {
      try {
        // User Credential Interface: https://firebase.google.com/docs/reference/js/auth.usercredential
        const userCredential = await signInWithEmailAndPassword(Auth, payload.email, payload.password)
        const user = userCredential.user
        const userRef = await doc(DB, 'user', user.uid)
        const userSnap = await getDoc(userRef)
        
        const idTokenResult = await user.getIdTokenResult()

        let userProfile = {}
        if (idTokenResult.claims.admin || idTokenResult.claims.user) {
          await setDoc(userRef, { isAdmin: idTokenResult.claims.admin ? idTokenResult.claims.admin : false }, { merge: true })
          await setDoc(userRef, { isUser: idTokenResult.claims.user ? idTokenResult.claims.user : false }, { merge: true })
          
          const paycorRef = await doc(DB, 'paycorOAuth/inLYHNI3QtERmKyWQ49f')
          const paycorSnap = await getDoc(paycorRef)

          const adaptiveWorkRef = await doc(DB, 'adaptiveWork/TxxCihdf7hxfJjzAJ1Ep')
          const adaptiveWorkSnap = await getDoc(adaptiveWorkRef)

          userProfile = {
            firstName: userSnap.data().firstName,
            lastName: userSnap.data().lastName,
            email: userSnap.data().email,
            uid: userSnap.data().uid,
            emailVerified: userSnap.data().emailVerified,
            isAdmin: idTokenResult.claims.admin ? idTokenResult.claims.admin : false,
            isUser: idTokenResult.claims.user ? idTokenResult.claims.user : false,
            isAnonymous: userSnap.data().isAnonymous,
            accessTokenAdo: userSnap.data().accessTokenAdo,
            refreshTokenAdo: userSnap.data().refreshTokenAdo,
            accessTokenPaycor: paycorSnap.data().accessTokenPaycor,
            refreshTokenPaycor: paycorSnap.data().refreshTokenPaycor,
            accessTokenPaycorSandbox: paycorSnap.data().accessTokenPaycorSandbox,
            refreshTokenPaycorSandbox: paycorSnap.data().refreshTokenPaycorSandbox,
            sessionIdAdaptiveWorkSandbox: adaptiveWorkSnap.data().sessionIdAdaptiveWorkSandbox,
          }
        } else {
          await setDoc(userRef, { isAdmin: idTokenResult.claims.admin ? idTokenResult.claims.admin : false }, { merge: true })
          await setDoc(userRef, { isUser: idTokenResult.claims.user ? idTokenResult.claims.user : false }, { merge: true })
            userProfile = {
              firstName: userSnap.data().firstName,
              lastName: userSnap.data().lastName,
              email: userSnap.data().email,
              uid: userSnap.data().uid,
              emailVerified: userSnap.data().emailVerified,
              isAdmin: idTokenResult.claims.admin ? idTokenResult.claims.admin : false,
              isUser: idTokenResult.claims.user ? idTokenResult.claims.user : false,
              isAnonymous: userSnap.data().isAnonymous,
              accessTokenAdo: userSnap.data().accessTokenAdo,
              refreshTokenAdo: userSnap.data().refreshTokenAdo,
              accessTokenPaycor: '',
              refreshTokenPaycor: '',
              accessTokenPaycorSandbox: '',
              refreshTokenPaycorSandbox: '',
              sessionIdAdaptiveWorkSandbox: '',
            }
        }

        if(userSnap.exists()){
          commit('updateUserState', userProfile)
        } else {
          console.log("No User Data")
        }
      } catch (e) {
        alert('Vuex User Store (action: loginAccount) (Account Login Error): ' + e.message)
      }
    },

    async monitorAuthState({ commit, state }) {
      let p = new Promise((resolve, reject) => {
        onAuthStateChanged(Auth, async user => {
          const idTokenResult = await user.getIdTokenResult()
          let userProfile = {}
  
          let userSnap = await getDoc(doc(DB, 'user', user.uid))
  
          if (user.uid === state.uid) {
            resolve('UID is Set')
          } else if (user && (idTokenResult.claims.admin || idTokenResult.claims.user)) {
              const paycorRef = await doc(DB, 'paycorOAuth/inLYHNI3QtERmKyWQ49f')
              const paycorSnap = await getDoc(paycorRef)
  
              const adaptiveWorkRef = await doc(DB, 'adaptiveWork/TxxCihdf7hxfJjzAJ1Ep')
              const adaptiveWorkSnap = await getDoc(adaptiveWorkRef)
    
              userProfile = {
                firstName: userSnap.data().firstName,
                lastName: userSnap.data().lastName,
                email: userSnap.data().email,
                uid: userSnap.data().uid,
                isAdmin: userSnap.data().isAdmin,
                isUser: userSnap.data().isUser,
                emailVerified: userSnap.data().emailVerified,
                isAnonymous: userSnap.data().isAnonymous,
                accessTokenAdo: userSnap.data().accessTokenAdo,
                refreshTokenAdo: userSnap.data().refreshTokenAdo,
                accessTokenPaycor: paycorSnap.data().accessTokenPaycor,
                refreshTokenPaycor: paycorSnap.data().refreshTokenPaycor,
                accessTokenPaycorSandbox: paycorSnap.data().accessTokenPaycorSandbox,
                refreshTokenPaycorSandbox: paycorSnap.data().refreshTokenPaycorSandbox,
                sessionIdAdaptiveWorkSandbox: adaptiveWorkSnap.data().sessionIdAdaptiveWorkSandbox,
              }
              commit('updateUserState', userProfile)
              resolve('UID is Set')
            } else if (user) {
                userProfile = {
                  firstName: userSnap.data().firstName,
                  lastName: userSnap.data().lastName,
                  email: userSnap.data().email,
                  uid: userSnap.data().uid,
                  isAdmin: userSnap.data().isAdmin,
                  isUser: userSnap.data().isUser,
                  emailVerified: userSnap.data().emailVerified,
                  isAnonymous: userSnap.data().isAnonymous,
                  accessTokenAdo: userSnap.data().accessTokenAdo,
                  refreshTokenAdo: userSnap.data().refreshTokenAdo,
                  accessTokenPaycor: '',
                  refreshTokenPaycor: '',
                  accessTokenPaycorSandbox: '',
                  refreshTokenPaycorSandbox: '',
                  sessionIdAdaptiveWorkSandbox: '',
                }
                commit('updateUserState', userProfile)
                resolve('UID is Set')
            } else {
              reject('No User')
            }
        })
      })

      return p
    },

    async updateUserState({ commit, state }) {
      let p = new Promise((resolve, reject) => {
        getDoc(doc(DB, 'user', state.uid)).then(async (userSnap) => {
            const user = Auth.currentUser;
            const idTokenResult = await user.getIdTokenResult()

            let userProfile = {}
            if (idTokenResult.claims.admin || idTokenResult.claims.user) {
              const paycorRef = await doc(DB, 'paycorOAuth/inLYHNI3QtERmKyWQ49f')
              const paycorSnap = await getDoc(paycorRef)

              const adaptiveWorkRef = await doc(DB, 'adaptiveWork/TxxCihdf7hxfJjzAJ1Ep')
              const adaptiveWorkSnap = await getDoc(adaptiveWorkRef)  

              userProfile = {
                firstName: userSnap.data().firstName,
                lastName: userSnap.data().lastName,
                email: userSnap.data().email,
                uid: userSnap.data().uid,
                emailVerified: userSnap.data().emailVerified,
                isAdmin: idTokenResult.claims.admin,
                isUser: idTokenResult.claims.user,
                isAnonymous: userSnap.data().isAnonymous,
                accessTokenAdo: userSnap.data().accessTokenAdo,
                refreshTokenAdo: userSnap.data().refreshTokenAdo,
                accessTokenPaycor: paycorSnap.data().accessTokenPaycor,
                refreshTokenPaycor: paycorSnap.data().refreshTokenPaycor,
                accessTokenPaycorSandbox: paycorSnap.data().accessTokenPaycorSandbox,
                refreshTokenPaycorSandbox: paycorSnap.data().refreshTokenPaycorSandbox,
                sessionIdAdaptiveWorkSandbox: adaptiveWorkSnap.data().sessionIdAdaptiveWorkSandbox,
              }
            } else {
                userProfile = {
                  firstName: userSnap.data().firstName,
                  lastName: userSnap.data().lastName,
                  email: userSnap.data().email,
                  uid: userSnap.data().uid,
                  emailVerified: userSnap.data().emailVerified,
                  isAdmin: idTokenResult.claims.admin,
                  isUser: idTokenResult.claims.user,
                  isAnonymous: userSnap.data().isAnonymous,
                  accessTokenAdo: userSnap.data().accessTokenAdo,
                  refreshTokenAdo: userSnap.data().refreshTokenAdo,
                  accessTokenPaycor: '',
                  refreshTokenPaycor: '',
                  accessTokenPaycorSandbox: '',
                  refreshTokenPaycorSandbox: '',
                  sessionIdAdaptiveWorkSandbox: '',
                }
            }
            commit('updateUserState', userProfile)
            resolve('User State Updated')
          }).catch(() => {
            reject('Failed to Update User State')
          })        
      })
      return p
    },
    async refreshAccessTokenPaycor({ commit, state }) {
      try {
        const paycorRef = await doc(DB, 'paycorOAuth/inLYHNI3QtERmKyWQ49f')
        const res = await refreshPaycorOAuth({
          refreshToken: state.refreshTokenPaycor
        })

        await setDoc(paycorRef, {
          accessTokenPaycor: res.data.access_token,
          refreshTokenPaycor: res.data.refresh_token
        }, { merge: true })

        commit('updatePaycorOauthTokens', {
          accessTokenPaycor: res.data.access_token,
          refreshTokenPaycor: res.data.refresh_token,
        })
        return res
      } catch(err) {
        console.log('refreshAccessTokenPaycor err:', err)
        return err
      }
    },
    async refreshAccessTokenPaycorSandbox({ commit, state }) {
      try {
        const paycorRef = await doc(DB, 'paycorOAuth/inLYHNI3QtERmKyWQ49f')
        const res = await refreshPaycorOAuthSandbox({
          refreshToken: state.refreshTokenPaycorSandbox
        })

        await setDoc(paycorRef, {
          accessTokenPaycorSandbox: res.data.access_token,
          refreshTokenPaycorSandbox: res.data.refresh_token
        }, { merge: true })

        commit('updatePaycorOauthTokensSandbox', {
          accessTokenPaycorSandbox: res.data.access_token,
          refreshTokenPaycorSandbox: res.data.refresh_token,
        })
        return res
      } catch(err) {
        console.log('refreshAccessTokenPaycor err:', err)
        return err
      }
    },
    async refreshSessionIdAdaptiveWorkSandbox({ commit }) {
      try {
        const adaptiveWorkRef = await doc(DB, 'adaptiveWork/TxxCihdf7hxfJjzAJ1Ep')
        const res = await refreshSessionIdAdaptiveWorkSandbox()

        await setDoc(adaptiveWorkRef, {
          sessionIdAdaptiveWorkSandbox: res.data.sessionId
        }, { merge: true })

        commit('updateAdaptiveWorkSessionIdSandbox', {
          sessionIdAdaptiveWorkSandbox: res.data.sessionId,
        })
        return res
      } catch(err) {
        console.log('refreshAccessTokenPaycor err:', err)
        return err
      }
    },
    async refreshAccessTokenAdo({ commit, state }) {
      try {
        const userRef = await doc(DB, 'user', state.uid)
        const res = await refreshAdoOAuth({
          refreshToken: state.refreshTokenAdo
        })

        await setDoc(userRef, {
          accessTokenAdo: res.data.access_token,
          refreshTokenAdo: res.data.refresh_token
        }, { merge: true })

        commit('updateAdoOauthTokens', {
          accessTokenAdo: res.data.access_token,
          refreshTokenAdo: res.data.refresh_token,
        })
        return res
      } catch(err) {
        console.log('refreshAccessTokenAdo err:', err)
        return err
      }
    },
    signOut({ commit }) {
      signOut(Auth).then(() => {
        commit('resetUserState')
        router.push({name: 'Home'})
      })
    },
  }
}
