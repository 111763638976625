<template>
  <v-app>
    <v-app-bar
      app
      color="white"
    >
      <div color="#008DBC" class="d-flex align-center" style="
            width: 480px;
          ">
        <v-img
          alt="Next Level Solutions Logo"
          class=""
          contain
          src="./assets/NlsLogo-1.png"
          transition="scale-transition"
          width="40px"
          height="64px"
        />
        <span style="
            color: black;
            font-size: 2em;
            margin-left: 5px;
          "
        >
          Next Level Solutions
        </span>
      </div>


      <v-tabs
        v-model="selectedTab"
        class="pr-15"
        right>
        <v-tab  
            v-for="(link, index) in displayedLinks"
            :key="index"
            @click="toRoute(link.routeName)"
          >
            {{ link.name }}
          </v-tab>
      </v-tabs>

      <v-spacer></v-spacer>
      <v-btn
        icon
        class="h3 ma-3"
        color="#008DBC"
        @click="signOut"
        v-if="user.email"
      >Sign Out</v-btn>
    </v-app-bar>

    <v-main>
      <router-view style="height: 100%;"/>
    </v-main>
    <Footer v-on:updateSelectedTab="updateSelectedTab" :links="displayedLinks"></Footer>
  </v-app>
</template>

<script>
import router from './router'
import Vue from 'vue';
import Footer from './components/Footer.vue';
import { mapState, mapActions } from 'vuex'

export default Vue.extend({
  components: { Footer },
  name: 'App',

  computed: {
    ...mapState(['user']),
    displayedLinks() {
      if(this.user.isAdmin) {
        return this.links
      } else if (this.user.isUser) {
        return [
          {
            name: 'Update Adaptive Work',
            routeName: 'RegistrationLanding',
          },
          {
            name: 'Feature Level Csv',
            routeName: 'FeatureLevelCsv',
          }
        ]
      } else if (this.user.email) {
        return [
          {
            name: 'Registration Landing',
            routeName: 'RegistrationLanding',
          }
        ]
      } else {
        return []
      }
    },
    selectedTab() {
      return this.user.isAdmin ? 1 : 0
    }
  },
  methods: {
    ...mapActions(['signOut']),
    toRoute(routeName) {
      if(this.$route.name !== routeName) {
        router.push({ name: routeName })
      }
    },
    updateSelectedTab(tabIndex) {
      if (tabIndex) {
        this.selectedTab = tabIndex
      }
    }
  },
  data: () => ({
    links: [
      {
        name: 'Admin',
        routeName: 'Admin',
      },
      {
        name: 'Update Adaptive Work',
        routeName: 'RegistrationLanding',
      },
      {
        name: 'Feature Level Csv',
        routeName: 'FeatureLevelCsv',
      },
      {
        name: 'Test',
        routeName: 'Test',
      },
      {
        name: 'BriteCore Tools',
        routeName: 'BritecoreTools',
      }
    ],
  }),
});
</script>

<style scoped>
  main {
    background-color: #008DBC;
  }


</style>