import { App } from './app'
import { getFunctions, httpsCallableFromURL } from 'firebase/functions'

const Functions = getFunctions(App, "us-central1")
const addAdminRole = httpsCallableFromURL(Functions, "https://us-central1-nls-hours-integration-7d012.cloudfunctions.net/addAdminRole")
const removeAdminRole = httpsCallableFromURL(Functions, "https://us-central1-nls-hours-integration-7d012.cloudfunctions.net/removeAdminRole")
const addUserRole = httpsCallableFromURL(Functions, "https://us-central1-nls-hours-integration-7d012.cloudfunctions.net/addUserRole")
const removeUserRole = httpsCallableFromURL(Functions, "https://us-central1-nls-hours-integration-7d012.cloudfunctions.net/removeUserRole")
const adoOAuthPost = httpsCallableFromURL(Functions, "https://us-central1-nls-hours-integration-7d012.cloudfunctions.net/adoOAuthPost")
const refreshAdoOAuth = httpsCallableFromURL(Functions, "https://us-central1-nls-hours-integration-7d012.cloudfunctions.net/refreshAdoOAuth")

const paycorOAuthPostSandbox = httpsCallableFromURL(Functions, "https://us-central1-nls-hours-integration-7d012.cloudfunctions.net/paycorOAuthPostSandbox")
const refreshPaycorOAuthSandbox = httpsCallableFromURL(Functions, "https://us-central1-nls-hours-integration-7d012.cloudfunctions.net/refreshPaycorOAuthSandbox")

const paycorOAuthPost = httpsCallableFromURL(Functions, "https://us-central1-nls-hours-integration-7d012.cloudfunctions.net/paycorOAuthPost")
const refreshPaycorOAuth = httpsCallableFromURL(Functions, "https://us-central1-nls-hours-integration-7d012.cloudfunctions.net/refreshPaycorOAuth")

const createQuoteExtended = httpsCallableFromURL(Functions, "https://us-central1-nls-hours-integration-7d012.cloudfunctions.net/createQuoteExtended")
const retrievePolicyTerms = httpsCallableFromURL(Functions, "https://us-central1-nls-hours-integration-7d012.cloudfunctions.net/retrievePolicyTerms")
const retrieveRiskDetails = httpsCallableFromURL(Functions, "https://us-central1-nls-hours-integration-7d012.cloudfunctions.net/retrieveRiskDetails")

const uploadToAttachmentsFolder = httpsCallableFromURL(Functions, "https://us-central1-nls-hours-integration-7d012.cloudfunctions.net/uploadToAttachmentsFolder")

const refreshSessionIdAdaptiveWorkSandbox = httpsCallableFromURL(Functions, "https://us-central1-nls-hours-integration-7d012.cloudfunctions.net/refreshSessionIdAdaptiveWorkSandbox")

export { 
    addAdminRole, 
    removeAdminRole, 
    adoOAuthPost, 
    refreshAdoOAuth, 
    paycorOAuthPost, 
    refreshPaycorOAuth, 
    addUserRole, 
    removeUserRole, 
    paycorOAuthPostSandbox, 
    refreshPaycorOAuthSandbox, 
    createQuoteExtended,
    retrievePolicyTerms,
    retrieveRiskDetails,
    uploadToAttachmentsFolder,
    refreshSessionIdAdaptiveWorkSandbox,
}
