import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      if(store.state.user.email) {
        next('/registration-landing')
      } else {
        next()
      }
    }
  },
  {
    path: '/registration-landing',
    name: 'RegistrationLanding',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "registration-landing" */ '../views/PayrollCsv.vue'),
  },
  {
    path: '/test',
    name: 'Test',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "registration-landing" */ '../views/Test.vue'),
  },
  // {
  //   path: '/payroll-csv',
  //   name: 'PayrollCsv',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import('../views/PayrollCsv.vue'),
  //   beforeEnter: (to, from, next) => {
  //     if(store.state.user.isAdmin || store.state.user.isUser) {
  //       next()
  //     } else {
  //       next('/')
  //     }
  //   }
  // },
  {
    path: '/feature-level-csv',
    name: 'FeatureLevelCsv',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/FeatureLevelCsv.vue'),
    beforeEnter: (to, from, next) => {
      if(store.state.user.isAdmin || store.state.user.isUser) {
        next()
      } else {
        next(false)
      }
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "registration-landing" */ '../views/Admin.vue'),
    beforeEnter: (to, from, next) => {
      if(!store.state.user.isAdmin) {
        next(false)
      } else {
        next()
      }
    }
  },
  {
    path: '/britecore-tools',
    name: 'BritecoreTools',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "registration-landing" */ '../views/BritecoreTools.vue'),
    beforeEnter: (to, from, next) => {
      if(!store.state.user.isAdmin) {
        next(false)
      } else {
        next()
      }
    }
  },
  {
    path: '/non-admin-user',
    name: 'NonAdminUser',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "registration-landing" */ '../views/NonAdminUser.vue'),
    beforeEnter: (to, from, next) => {
      if(!store.state.user.email) {
        next(false)
      } else {
        next({name: 'Home'})
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
